<template>
	<div class="view view--public public">
		<router-view class="public__content" name="content" />
	</div>
</template>

<script>
export default {
	name: 'ViewPublic',
}
</script>

<style scoped lang="scss">

</style>
